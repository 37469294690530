import React from 'react';
import ProjectBox from './ProjectBox';
import OptinexTech from '../images/OptinexTech.png';
import Fashiostreet from '../images/Fashiostreet.png';

const Projects = () => {
  return (
    <div>
      <h1 className='projectHeading'>My <b>Projects</b></h1>
      <div className='project'>
        <ProjectBox projectPhoto={OptinexTech} projectName="OptinexTech" />
        <ProjectBox projectPhoto={Fashiostreet} projectName="Fashiostreet" />
      </div>

    </div>
  )
}

export default Projects