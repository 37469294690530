import React from 'react'; 
import { CgCPlusPlus } from "react-icons/cg";
import {FaReact, FaGitAlt, FaGithub, FaNpm, FaFigma, FaBootstrap, FaHtml5, FaCss3Alt, FaVuejs, FaPython, FaAws} from "react-icons/fa";
import {DiNodejs, DiJavascript1, DiJqueryLogo} from "react-icons/di";
import {SiExpress, SiMongodb, SiPostman, SiVercel, SiNextdotjs, SiNuxtdotjs, SiApachecordova, SiNestjs, SiGooglecloud} from "react-icons/si";
import { TbBrandReactNative } from "react-icons/tb";

const Skills = ({skill}) => {
    const icon = {
      'C++': <CgCPlusPlus/>,
      Postman: <SiPostman/>,
      React: <FaReact/>,
      Javascript: <DiJavascript1/>,
      Node : <DiNodejs/>,
      Express : <SiExpress/>,
      MongoDb : <SiMongodb/>,
      Git : <FaGitAlt/>,
      Github : <FaGithub/>,
      Npm : <FaNpm/>,
      Figma : <FaFigma/>,
      Bootstrap: <FaBootstrap/>,
      Vercel : <SiVercel/>,
      Html : <FaHtml5 />,
      CSS : <FaCss3Alt />,
      'Next.js' : <SiNextdotjs />,
      'Vue.js' : <FaVuejs />,
      Nuxt : <SiNuxtdotjs />,
      'React Native' : <TbBrandReactNative />,
      Jquery : <DiJqueryLogo />,
      'Apache Cordova' : <SiApachecordova />,
      Python : <FaPython />,
      'NestJS' : <SiNestjs />,
      AWS : <FaAws />,
      GCP : <SiGooglecloud />
    }
    
  return (
    <div title={skill} className='SkillBox'>
      {icon[skill]}
    </div>
  )
}

export default Skills
