import React from 'react';
import {FaGithub} from "react-icons/fa";
import {CgFileDocument} from "react-icons/cg";


const  ProjectBox = ({projectPhoto, projectName}) => {
  const desc = {
    FashiostreetDesc: `Fashiostreet helps to transform the Indian retail landscape by creating an innovative platform that brings together local retailers and enhances their online presence.`,
    FashiostreetGithub: 'https://github.com/RushiShirkar/fashiostreet-app',
    FashiostreetWebsite: '',

    OptinexTechDesc: `OptinexTech provides cutting-edge tech and software solutions to bridge the digital divide for Bharat's businesses, both rural and urban. OptinexTech also provides tech insights & blogs.`,
    OptinexTechGithub: '',
    OptinexTechWebsite: 'https://optinextech.com'
  }

  let showGithub = '';
  if(desc[projectName + 'Github'] === "") {
    showGithub = "none";
  }

  let showWebsite = '';
  if(desc[projectName + 'Website'] === "") {
    showWebsite = "none";
  }
    
  return (
    <div className='projectBox'> 
      <img className='projectPhoto' src={projectPhoto} alt="Project display" /> 
      <div>
        <h3>{projectName}</h3>
        <br />
        {desc[projectName + 'Desc']}
        <br />

        <a style={{display: showGithub}} href={desc[projectName + 'Github']} target='_blank'>
          <button className='projectbtn'><FaGithub/> Github</button>
        </a>

        <a style={{display: showWebsite}} href={desc[projectName + 'Website']} target='_blank'>
          <button className='projectbtn'><CgFileDocument/> Demo</button>
        </a>
      </div>
    </div>
  )
}

export default  ProjectBox