import React from 'react';
import Lottie from  "lottie-react";
import SpaceBoy from "../LottieFiles/SpaceBoy.json";
import Typed from "./Typed.js";
import Tilt from 'react-parallax-tilt';
import Avatar from '../images/Avatar.png';
import {CiCoffeeCup} from "react-icons/ci";

const Home = () => {
  return (
    <div >
      <div className='HomePage'>

        <div className='HomeText'>
          <h1>Hi There!</h1>
          <h1>I'M <b>RUSHIKESH SHIRKAR</b></h1>
          <Typed/>   
        </div>

        <Lottie 
          className="illustration" 
          animationData={SpaceBoy} 
          loop={true} 
        />
        
      </div>

      <div className='AboutPage'>
        <div className='AboutText'>
          <h1 className='AboutTextHeading'>Brief <b>introduction</b></h1>
          <p>
            I am passionate about transforming ideas into impactful digital products. <br />
            With expertise in front-end technologies like <b>React</b>, <b>Vue.js</b>, and <b>Next.js</b>, 
            I thrive on building user-friendly, scalable <b>web & mobile applications</b>. <br/>
            I’m driven by a love for continuous learning and innovation, constantly 
            exploring new <b>frameworks and tools</b> to enhance my development skills.<br /><br />
            In addition to my technical proficiency, I enjoy contributing to projects that 
            challenge me and allow me to make a meaningful difference<br />
            Also, I love <b>sports</b> and enjoy staying active. 
          </p>
        </div>
        <Tilt>
          <img className='Avatar' src={Avatar} alt="" />
        </Tilt>
      </div>
    </div>
  )
}

export default Home